import React from 'react';
import classNames from 'classnames';

export const SectionPractical = ({ columns }) => {
  const className = classNames({
    'practical': true
  });

  return (
    <section className={className}>
      <div className="practical__inner">
        { columns.map(column => <PracticalColumn key={column.id} {...column} />) }
      </div>
    </section>
  )
}

export const PracticalColumn = ({
  title,
  body,
  buttonLabel,
  buttonTarget
}) => {
  const className = classNames({
    'practical-column': true
  });

  return (
    <div className={className}>
      <h3 className="practical-column__title">{title}</h3>
      <div className="practical-column__body" dangerouslySetInnerHTML={{ __html: body }} />
      { buttonLabel && (
        <div className="practical-column__button">
          <a href={buttonTarget} target="_blank" className="button">{buttonLabel}</a>
        </div>
      ) }
    </div>
  )
}