import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image"
import classNames from 'classnames';

export class Asset extends React.Component {
  get isVideo () {
    return this.props.mimeType === 'video/mp4';
  }

  renderImage = () => (
    <GatsbyImage image={this.props.image} loading="eager" critical="true" fadeIn={false}	/>
  )

  renderVideo = () => (
    <video
      controls={!!this.props.controls}
      playsInline={!!this.props.playsInline}
      autoPlay={!!this.props.autoPlay}
      src={this.url}
    />
  )

  render = () => {
    if (this.isVideo) return this.renderVideo();
    return this.renderImage();
  }
}