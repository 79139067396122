import React from 'react';
import classNames from 'classnames';

export class SectionBooking extends React.Component {
  get className () {
    return classNames({
      'booking': true
    });
  }

  componentDidMount () {
    setTimeout(() => {
      const resengoForm = this.props.columns[0].resengoForm[0];
      console.log(resengoForm);
      window.RESENGO_WIDGET({ ...resengoForm, mode:"iframe"});
    }, 1000);
  }

  render = () => (
    <div className={this.className}>
      <div className="booking__inner">
        <div className="booking__columns">
          { this.props.columns.map(column => (
            <div className="booking__columns__column" data-sal="fade"> 
              <div className="booking__columns__column__title">{ column.title }</div>
              <div className="booking__columns__column__body" dangerouslySetInnerHTML={{ __html: column.body }}/>
              { column.resengoForm && column.resengoForm.length == 1 && (
                <div className="booking__columns__column__widget" dangerouslySetInnerHTML={{ __html: column.resengoForm[0].embedCode }} />
              ) } 
            </div>
          )) }
        </div>
        <div className="booking__footer" data-sal="fade">
          <div className="booking__footer__content" dangerouslySetInnerHTML={{ __html: this.props.footer }} />
        </div>
      </div>
    </div>
  )
}