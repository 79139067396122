import React from 'react';
import classNames from 'classnames';
import { Asset } from '../../components/asset';
import { Link } from 'gatsby';

export class SectionHomepagePicker extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  get className () {
    return classNames({
      'homepage-picker': true
    });
  }

  render = () => (
    <section className={this.className}>
      { this.props.blocks.map(block => (
        <div className="homepage-picker__block">
          <div className="homepage-picker__block__image">
            <Asset image={block.image.local.childImageSharp.gatsbyImageData} aria-hidden />
          </div>
          <div className="homepage-picker__block__content">
            <div className="homepage-picker__block__title">{ block.title }</div>
            <div className="homepage-picker__block__button">
              <Link to={block.buttonTarget.slug} className="button">{ block.buttonLabel }</Link>
            </div>
          </div>
          
        </div>
      )) }
    </section>
  )
}