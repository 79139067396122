import React from 'react';
import classNames from 'classnames';
import { Asset } from '../../components/asset';

export class SectionCarousel extends React.Component {
  state = {
    activeSlide: 0,
    previousSlide: -1
  }

  get className () {
    return classNames({
      'carousel': true
    });
  }

  componentDidMount () {
    this.interval = setInterval(this.next, 3000);
  }

  componentWillUnmount () {
    clearInterval(this.interval);
  }

  select = (activeSlide) => {
    this.setState({
      previousSlide: this.state.activeSlide,
      activeSlide
    });
  }

  next = () => {
    let next = this.state.activeSlide + 1;
    if (next === this.props.images.length) next = 0;
    this.select(next);
  }

  render = () => (
    <section className={this.className} data-sal="fade">
      <div className="carousel__images">
        { 
          this.props.images.map((slide, index) => {
            let className = classNames({
              'carousel__images__slide': true,
              'carousel__images__slide--active': index === this.state.activeSlide,
              'carousel__images__slide--previous': index === this.state.previousSlide
            });

            return (
              <div className={className}>
                <Asset image={slide.local.childImageSharp.gatsbyImageData} />
              </div> 
            );
          })
        }

        <div className="carousel__images__spacer">
          <Asset image={this.props.images[0].local.childImageSharp.gatsbyImageData} />
        </div>
      </div>
      <div className="carousel__dots">
       { this.props.images.map((slide, index) => {
          let className = classNames({
            'carousel__dots__dot': true,
            'carousel__dots__dot--active': index === this.state.activeSlide
          });
          return <div className={className} onClick={() => this.select(index)} />
        })}
      </div>
    </section>
  )
}