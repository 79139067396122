import React from 'react';
import classNames from 'classnames';
import { Asset } from '../../components/asset';

export class SectionNikeiHeader extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  get className () {
    return classNames({
      'nikei-header': true
    });
  }

  render = () => (
    <section className={this.className} data-sal="fade" data-sal-easing="ease">
      <Asset image={this.props.imageDesktop.local.childImageSharp.gatsbyImageData} />
    </section>
  )
}