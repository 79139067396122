import React from 'react';
import classNames from 'classnames';

import Instagram from './instagram.inline.svg';
import Facebook from './facebook.inline.svg';
import { GatsbyImage } from 'gatsby-plugin-image';

export class Footer extends React.Component {
  get className () {
    return classNames({
      'footer': true
    });
  }

  render = () => {
    const {
      column1Title,
      column1Body,
      column2Title,
      column2Body,
      column3Title,
      socialMediaLinks,
      partnersTitle,
      partners,
    } = this.props;
    
    return (
      <footer className={this.className}>
        <div className="footer__inner">
          <div className="footer__logo">
            Stable
          </div>
          <div className="footer__right footer__right--flex">
            <div className="footer__right__column">
              <strong>{column1Title}</strong>
              <div dangerouslySetInnerHTML={{ __html: column1Body }} />
            </div>
            <div className="footer__right__column">
              <strong>{column2Title}</strong>
              <div dangerouslySetInnerHTML={{ __html: column2Body }} />
            </div>
            <div className="footer__right__column">
              <strong>{column3Title}</strong>
              <div className="footer__right__column__footer">
                { socialMediaLinks.map(link => (
                  <a title={link.title} href={link.url} target="_blank">
                    <GatsbyImage image={link.icon.local.childImageSharp.gatsbyImageData} />
                  </a>
                )) }
              </div>
            </div>
          </div>
        </div>
        <div className="footer__inner">
          <div className="footer__logo">

          </div>
          <div className="footer__right footer__right--bottom">
            <strong>{partnersTitle}</strong>
            { partners.map(link => (
              <a href={link.url} target="_blank">{link.label}</a>
            )) }
          </div>
        </div>
      </footer>
    );
  }
}