import React from 'react';
import classNames from 'classnames';

export class SectionSideBySide extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  get className () {
    return classNames({
      'side-by-side': true
    });
  }

  render = () => (
    <section className={this.className}>
      <div className="side-by-side__inner">
        <div className="side-by-side__inner__left">
          <h2 className="side-by-side__inner__left__text" data-sal="fade" data-sal-easing="ease">
            { this.props.textLeft }
          </h2>
        </div>
        <div className="side-by-side__inner__right">
          <div className="side-by-side__inner__right__text" dangerouslySetInnerHTML={{ __html: this.props.textRight }} data-sal="fade" data-sal-easing="ease" />
        </div>
      </div>
    </section>
  )
}