import React from 'react';
import classNames from 'classnames';
import { Asset } from '../../components/asset';
import { Link } from 'gatsby';

export class SectionImageWithText extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  get className () {
    return classNames({
      'image-with-text': true,
      'image-with-text--flipped': this.props.flipped,
      'image-with-text--centered': this.props.centered,
      'image-with-text--top': this.props.alignedToTop,
      [`image-with-text--${ this.props.extraClass }`]: !!this.props.extraClass
    });
  }

  render = () => (
    <section className={this.className}>
      <div className="image-with-text__inner">
        <div className="image-with-text__image" data-sal="fade">
          <Asset image={this.props.image?.local.childImageSharp.gatsbyImageData} />
        </div>
        <div className="image-with-text__content">
          <div className="image-with-text__content__title" data-sal="fade">{ this.props.title }</div>
          <div className="image-with-text__content__body" dangerouslySetInnerHTML={{ __html: this.props.body }} data-sal="fade" data-sal-easing="ease" />

          { this.props.buttonLabel && (
            <div className="image-with-text__content__button" data-sal="fade">
              <Link className="button" to={this.props.buttonTarget}>{ this.props.buttonLabel }</Link>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}