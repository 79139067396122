import React from 'react';
import classNames from 'classnames';
import { Asset } from '../../components/asset';

export class SectionFullWidthImage extends React.Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  get className () {
    return classNames({
      'full-width-image': true
    });
  }

  render = () => {
    console.log(this.props.image);
    
    return (
      <section className={this.className}>
        <Asset image={this.props.image.local.childImageSharp.gatsbyImageData} />
      </section>
    )
  }
}